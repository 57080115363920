import React, { useState, useEffect, useRef, createRef } from "react"
import { Helmet } from "react-helmet";
import { Container, Row, Card, Col } from "react-bootstrap";
import axios from "axios"


import Layout from "../components/layout";
import SEO from "../components/seo";
import Banner from "../components/Home/Banner/Banner.js";
import MarketAnalysis from "../components/Home/MarketAnalysis/MarketAnalysis";
import MarketVideo from "../components/Home/MarketVideo/MarketVideo";
import ProjectRating from "../components/Home/ProjectRating/ProjectRating"
import LatestBlogs from "../components/Home/LatestBlogs/LatestBlogs"
import Reviews from "../components/Home/Reviews/Reviews"
import Breadcrumbs from "../components/Breadcrumbs/Breadcrumbs"
import Insights from "../components/InsightsPeoples/Insights"
import OurPeoples from "../components/InsightsPeoples/OurPeoples"
import ContentBlock from "../components/Content/Content"
import StepContentBlock from "../components/Content/StepContent";
import AccordionBlock from "../components/AccordionBlock/AccordionBlock"
import FeaturedNewHome from "../components/FeaturedNewHome/FeaturedNewHome";
import ValuationAccordionBlock from "../components/AccordionBlock/ValuationAccordionBlock"
import NewProjects from "../components/NewProjects/NewProjects"
import TableuAccordionBlock from "../components/AccordionBlock/TableuAccordionBlock"
import LatestBlogsbyCategory from "../components/StaticOne/FilterBlock/LatestBlogsbyCategory"

import MemberCard from "../components/MemberCard/MemberCard"
import TitleBlock from "../components/TitleBlock/TitleBlock"
import BottomBlock from "../components/BottomBlock/BottomBlock"
import IntroBlock from "../components/IntroCopyBlock/IntroBlock";
import Communities from "../components/Communities/Communities"

import ThankyouBlock from "../components/ThankyouBlock/ThankyouBlock"

import PropertyToolkit from "../components/Valuation/PropertyToolkit/PropertyToolkit"
import PlayVideo from "../components/Play/PlayVideo";
import wordsToNumbers from 'words-to-numbers';
import { removeDataAttributes } from "../comQueryStructure.js";
import { google_review_url } from "../components/common/utils.js";


function HomeModules(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [isPlay, setPlay] = useState(false);
  const [videoId, setVideoId] = useState('');
  const [modalHeading, setModalHeading] = useState('');
  const [modalType, setModalType] = useState('');

  const find_more_ref = createRef();

  const [testimonials, setTestimonials] = useState([]);

  const [renderComponent, setRenderComponent] = useState(false);


  const strapiconfig = {
    headers: {
      Authorization:
        `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
    },
  }
  //console.log('home module');
  var ggl_reviews = [];
  useEffect(() => {

     const getitems = async url => {
    try {
      const { data } = await axios.get(url, strapiconfig)// could be from env files

      const reviewdata = (data)
      if (reviewdata && reviewdata.length > 0) {
        reviewdata.map((item, index) => {
          var star_no = wordsToNumbers(item.starRating);
          if (star_no > 3) {
            if (item.comment) {
              ggl_reviews.push(item); 
            }
          }

        })
      }
      setTestimonials(data);
    } catch (error) {
      // cache it if fail/error;
      console.error(error)
    }
  }

    const handleUserInteraction = () => {
      if (!renderComponent) {
        setRenderComponent(true);
        const url = google_review_url;
        getitems(url);
      }
    };

    ['mousemove', 'touchmove', 'keypress'].forEach(eventType => {
      window.addEventListener(eventType, handleUserInteraction);
    });

    return () => {
      ['mousemove', 'touchmove', 'keypress'].forEach(eventType => {
        window.removeEventListener(eventType, handleUserInteraction);
      });
    };
  }, [renderComponent]);

  const handleMouseOver = () => {
    setRenderComponent(true);
  };

 
  // const getitems = async url => {
  //   try {
  //     const { data } = await axios.get(url, strapiconfig)// could be from env files

  //     // const reviewdata = (data)
  //     // if (reviewdata && reviewdata.length > 0) {
  //     //   reviewdata.map((item, index) => {
  //     //     var star_no = wordsToNumbers(item.starRating);
  //     //     if (star_no > 3) {
  //     //       if (item.comment) {
  //     //         ggl_reviews.push(item); 
  //     //       }
  //     //     }

  //     //   })
  //     // }
  //     setTestimonials(data);
  //   } catch (error) {
  //     // cache it if fail/error;
  //     console.error(error)
  //   }
  // }

  const openModalForm = (e, modal_heading, modal_type) => {
    e.preventDefault();
    setIsOpen(true);
    setModalHeading(modal_heading);
    setModalType(modal_type);
  }

  const openVideoModal = (video_id) => {
    setVideoId(video_id);
    setPlay(true)
  }



  const findoutMore = () => {
    find_more_ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }



  var GQLPage = props.GQLPage;
  var GQLModules = props.GQLModules;
  var GlobalModules = props.GlobalModules;
  var BannerImage = GQLPage && GQLPage.Header_Banner_Image;
  var BannerVideo = GQLPage && GQLPage.Header_Banner_Video;

  let processedImages = JSON.stringify({});
  if (GQLPage?.imagetransforms && GQLPage?.imagetransforms.Header_Banner_Image_Transforms) {
    processedImages = GQLPage?.imagetransforms.Header_Banner_Image_Transforms;
  }


  var main_cnt_cls = (GQLPage?.Layout !== "Default" && GQLPage?.Layout !== "Buy_Landing_Page") ? "page-content" : '';
  main_cnt_cls += (GQLPage?.Layout === "Content_Full_Width" || GQLPage?.Layout === "Rightside_Block") ? " static-page" : '';

  var middle_cls = (GQLPage?.Layout !== "Default" && GQLPage?.Layout !== "Buy_Landing_Page") && "middle_cnt";

  middle_cls += GQLModules && GQLModules.length > 0 && GQLModules.filter(Modules => Modules.__typename === "GLSTRAPI_ComponentModRightsideModule").length > 0 ? ' col-xl-8' : '';

  middle_cls += GQLPage?.Layout === "Content_Full_Width" ? ' col-xl-12' : '';

  middle_cls += GQLModules && GQLModules.length > 0 && GQLModules.filter(Modules => Modules.__typename === "GLSTRAPI_ComponentModIntro").length > 0 ? ' pb-0' : '';


  return (
    <>
      {
        props.GQLPage && (

          <Layout Layout={GQLPage.Layout} classNames={GQLPage.Layout} popular_search={GQLPage.popular_search} Alias={GQLPage.Alias} Select_Popular_Search={GQLPage.Select_Popular_Search} Pagename={GQLPage.Pagename}>

            <SEO title={GQLPage.Meta_Title} description={GQLPage.Meta_Description} />

            <Helmet bodyAttributes={{ class: `homepage ${GQLPage ? GQLPage.Layout : ''} ${GQLPage ? GQLPage.Custom_CSS_Class : ''}` }} />

            {/* ====== Filter Header Banner ===== */}

            {
              GQLModules && GQLModules.length > 0 && GQLModules.filter(Modules => Modules.__typename === "GLSTRAPI_ComponentModHeaderBanner" || "GLSTRAPI_ComponentComMultifamilyHeader").map((Modules, i) => {

                var image_name = (GQLPage.Layout === "Content_Full_Width" || GQLPage.Layout === "Rightside_Block") ? 'article.Header_Banner_Image.inner_header' : 'article.Header_Banner_Image.header';

                return (
                  <>
                    {/* ======= Header Animate Banner Modules ====== */}
                    {BannerImage && Modules.Header_Title &&
                      <Banner Banner_Title={Modules.Header_Title && Modules.Header_Title} CTA_1_Label={Modules.CTA_1_Label && Modules.CTA_1_Label} CTA_1_URL={Modules.CTA_1_URL && Modules.CTA_1_URL} CTA_2_Label={Modules.CTA_2_Label && Modules.CTA_2_Label} CTA_2_URL={Modules.CTA_2_URL && Modules.CTA_2_URL} Banner_Image={BannerImage} Banner_Video={BannerVideo} Content={Modules.Header_Content ? Modules.Header_Content : Modules.Content} Layout={GQLPage.Layout} openModalForm={openModalForm} imagename={image_name} article_id={GQLPage.id} imagetransforms={GQLPage.ggfx_results} handleClick={findoutMore} GQLModules={GQLModules} GQLPage={GQLPage} Show_Banner_Google_Review={Modules.Show_Banner_Google_Review} testimonials={testimonials} Alias={GQLPage.Alias} Show_Enquire_Form={Modules.Show_Enquire_Form} Form_Heading={Modules.Form_Heading} Play_Button={GQLPage.Play_Button} location={props.location} description={GQLPage.Meta_Description} template={"common"} />
                    }
                  </>
                )
              })
            }
            {
              <div className={`${main_cnt_cls} ${GQLPage.Page_CSS_Class ? GQLPage.Page_CSS_Class : ''}`} ref={find_more_ref}>
                <Container>
                  <Row>
                    <div className={middle_cls}>
                      {/* ======= Breadcrumbs ====== */}
                      {(GQLPage.Layout === "Without_Banner" && GQLPage.Alias !== "valuation" && GQLPage.Alias !== "valuation-confirmation" && GQLPage.Alias !== "pre-qualified-confirmation")
                        ?
                        <>
                          <Breadcrumbs />
                          {
                            GQLPage.Alias !== "new-projects" && GQLPage.Alias !== "new-projects-rent" && GQLPage.Alias !== "new-projects-rent-in-dubai" && GQLPage.Alias !== "our-new-projects-in-dubai" && GQLPage.Pagename && GQLPage.Content && <TitleBlock Title={GQLPage.Pagename} Alias={GQLPage.Alias} Description={GQLPage.Content} />

                          }

                        </>
                        :
                        GQLPage.Alias != "contact-us" && GQLPage.Content && <ContentBlock Content={GQLPage.Content} Content_Heading_Text={GQLPage.Content_Heading_Text} classNames={GQLPage.Layout} page={GQLPage} />
                      }



                      {/* ====== Main Container Modules ===== */}
                      {GQLModules.map((Modules, i) => {

                        return (
                          <>
                            {/* ======= Inner Page Content Block ====== */}
                            {GQLPage.Alias !== "careers" && GQLPage.Alias !== "reviews" && Modules.__typename === "GLSTRAPI_ComponentModContentBlock" &&
                              <ContentBlock Content={Modules.Content} Content_Heading_Text={GQLPage.Content_Heading_Text} classNames={GQLPage.Layout} page={GQLPage} />
                            }
                            {/* ======= Inner Page Step Content Block ====== */}
                            {Modules.__typename === "GLSTRAPI_ComponentComStepContentBlock" && Modules.Steps &&
                              <StepContentBlock StepContent={Modules.Steps} />
                            }

                            {/* ======= Accordion Block ====== */}
                            {
                              Modules.Add_Accor_Item && Modules.Add_Accor_Item.length > 0 &&
                              <AccordionBlock AccordionItems={Modules.Add_Accor_Item} ViewMore={false} classNames={GQLPage.Layout} />
                            }

                            {
                              Modules.Tableu_Accordian && Modules.Tableu_Accordian.length > 0 && Modules.__typename === "GLSTRAPI_ComponentModTableuAccordianBlock" &&
                              <TableuAccordionBlock AccordionItems={Modules.Tableu_Accordian} ViewMore={false} classNames={GQLPage.Layout} activeId={"0"} location={props.location} />
                            }

                            {/* ======= Insights Page ====== */}
                            {Modules.Select_Collection === "Insights" && <Insights location={props.location} />}

                            {/* ======= New Projects Page ====== */}
                            {Modules.Select_Collection === "New_Projects" && <NewProjects Title={GQLPage.Pagename} Alias={GQLPage.Alias} pstatus={GQLPage.Alias === "new-projects" ? "Sale" : "Rent"} Description={GQLPage.Content} location={props.location} region={"Qatar"} sort={"createdAt:desc"} Meta_Description={GQLPage.Meta_Description} Meta_Title={GQLPage.Meta_Title} />}

                            {/* ======= New Projects Dubai Page ====== */}
                            {Modules.Select_Collection === "New_Projects_Dubai" && <NewProjects Title={GQLPage.Pagename} Alias={GQLPage.Alias} pstatus={GQLPage.Alias === "our-new-projects-in-dubai" ? "Sale" : "Rent"} Description={GQLPage.Content} location={props.location} region={"Dubai"} sort={"createdAt:desc"} Meta_Description={GQLPage.Meta_Description} Meta_Title={GQLPage.Meta_Title} />}


                            {/* ======= Our Peoples Page ====== */}
                            {Modules.Select_Collection === "Peoples" && <OurPeoples location={props.location} />}

                            {/* ======= Our Communities Page ====== */}
                            {Modules.Show_Community_List && <Communities location={props.location} />}


                            {/* ======= Valuation Confirmation ====== */}
                            {
                              Modules.__typename === "GLSTRAPI_ComponentModValuationThankyou" &&
                              <ThankyouBlock Pagename={GQLPage.Pagename} Modules={Modules} Alias={GQLPage.Alias} />
                            }

                            {
                              Modules.__typename === "GLSTRAPI_ComponentComToolkit" &&
                              <PropertyToolkit Toolkit={Modules} />
                            }

                            {
                              Modules.__typename === "GLSTRAPI_ComponentModValautionAccordianBlock" &&

                              <ValuationAccordionBlock Accordian_Title={Modules.Title} AccordionItems={Modules.Accordian_Block} ViewMore={true} classNames={GQLPage.Layout} ViewMoreLabel={Modules.CTA_1_Label} ViewMoreLink={Modules.CTA_1_URL} />
                            }


                          </>
                        )
                      })}


                      {/* ====== Rightside Modules ===== */}
                      {
                        GQLPage.Layout != "Calculator" &&
                        GQLModules && GQLModules.length > 0 && GQLModules.filter(Modules => Modules.__typename === "GLSTRAPI_ComponentModRightsideModule").map((Modules, i) => {
                          return (
                            <>
                              {Modules.modules && Modules.modules.length > 0 &&
                                <div className="col-xl-4 right_side"><div className="member-card-block">
                                  <MemberCard RightSideModule={Modules.modules[0].ModuleType[0]} openModalForm={openModalForm} Pagename={GQLPage.Pagename} MetaTitle={GQLPage.Meta_Title} WhatsappText={GQLPage.Pagename} page={GQLPage} location={props.location} template={"common"} /> </div></div>}
                            </>
                          )
                        })
                      }
                    </div>
                  </Row>
                </Container>

                {/* ====== Main Container Modules ===== */}
                {GQLModules.map((Modules, i) => {
                  return (
                    <>
                      {/* ======= Featured new projects for sale qatar module ====== */}
                      {
                        Modules.__typename === "GLSTRAPI_ComponentComFeaturedNewProjectsModuleQatar" &&
                        <FeaturedNewHome featured_details={Modules} pstatus={"Sale"} location={props.location} region={"Qatar"} sort={"createdAt:desc"} />
                      }
                      {/* ======= Graph Analytics ====== */}
                      {/* {
                            Modules.Add_Tabs != null && Modules.Add_Tabs.length > 0 && Modules.Add_Tabs &&
                            <MarketAnalysis Tab_Data={Modules.Add_Tabs} Alias={GQLPage.Alias} />
                          } */}
                      {/* ======= Full Width Video ====== */}
                      {Modules.Embed_Video_URL != null && Modules.Background_Image &&
                        <MarketVideo Embed_Video_URL={Modules.Embed_Video_URL} Background_Image={Modules.Background_Image} Video_Label={Modules.Video_Label && Modules.Video_Label} mute={false} />
                      }

                      {/* ======= Bottom Content Block ====== */}
                      {Modules.__typename === "GLSTRAPI_ComponentModBottomBlock" &&
                        <BottomBlock Bottom_Block={Modules} openModalForm={openModalForm} />}

                      {/* ======= Intro Content Block ====== */}
                      {Modules.__typename === "GLSTRAPI_ComponentModIntroCopyBlock" &&
                        <IntroBlock IntroTitle={Modules?.Intro_Copy_Title} IntroPara={Modules?.Intro_Copy_Content} />
                      }

                      {/* ======= Featured Properties ====== */}
                      { renderComponent &&Modules.Show_Property && <ProjectRating Title={Modules.Title} Alias={GQLPage.Alias} StbList={Modules.Stb_List && Modules.Stb_List} />}

                      {/* ======= Google Reviews ====== */}
                      {renderComponent && Modules.Show_Google_Review && <div className="d-md-flex flex-wrap"> <Reviews testimonials={testimonials} /> </div>}

                      {/* ======= Lastest Blogs Insights-Case Studies ====== */}
                      {(GQLPage.Layout === "Default" || GQLPage.Layout === "Buy_Landing_Page") && Modules.Show_Insights &&
                        <div className="d-md-flex flex-wrap">
                          <LatestBlogs Insights_Block={GlobalModules?.Insights_Block} Filter_by_Tag={Modules?.Filter_by_Tag} /> </div>
                      }
                      {Modules.__typename =="GLSTRAPI_ComponentModBlogCategoriesList" && 
                            <div className="">
                              <LatestBlogsbyCategory Filter_by_Tag={Modules?.categories} /> 
                            </div>
                          }
                    </>
                  )
                })}



                <PlayVideo
                  isOpen={isPlay}
                  isCloseFunction={setPlay}
                  videoId={videoId}
                  isAutoPlay={1}
                />
              </div>

            }

          </Layout>

        )
      }
    </>
  )
}

export default HomeModules