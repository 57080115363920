import { graphql } from 'gatsby'
import React from 'react'
import { useLocation } from '@reach/router'

import { removeDataAttributes } from '../comQueryStructure'
import HomeModules from './home-modules'

import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../scss/bootstrap-overrides.scss";
import "../scss/global.scss";
import "../scss/grunticon.scss";
import "../components/Home/Home.scss"
import '../scss/custom.scss';
import '../scss/custom-responsive-style.scss';

const HomeTemplate = ({ data }) => {
  const pageData = removeDataAttributes(data)
  let location = useLocation();
  let GQLPage = pageData.glstrapi?.article;
  let GQLModules = pageData.glstrapi?.article?.Add_Modules;
  let GlobalModules = pageData.glstrapi?.globalModule;

  return (
    <HomeModules GQLPage={GQLPage} GQLModules={GQLModules} GlobalModules={GlobalModules} location={location} />
  )
}
/*


*/
export const query = graphql`
  query homeQuery ($articleId: ID!) {
    glstrapi {
      globalModule {
        data {
          attributes {
            Insights_Block {
              Small_Text
              Content
              CTA_1_Link
              CTA_1_Label
              Title
            }
          }
        }
      }

      articles {
        data {
          attributes {
            Add_Modules {
              __typename
              
              ... on GLSTRAPI_ComponentModTableuAccordianBlock {
                id
                Tableu_Accordian {
                  Accordian_Content
                  Accordian_Heading
                  Tableu_Embed_Code
                  id
                }
              }
            }
          }
        }
      }

      article(id: $articleId) {
        data {
          attributes {
            Alias
            Content_Heading_Text
            Content
            Layout
            Meta_Title
            Meta_Description
            Page_CSS_Class
            Custom_CSS_Class
            Pagename
            Publish
            Select_Popular_Search
            imagetransforms
            ggfx_results {
              data {
                attributes {
                  src_cftle
                  transforms 
                }
              }
            }
            Header_Banner_Image {
              data {
                id
                attributes {
                  url
                  alternativeText
                }
              }
            }
            Header_Banner_Video
            Play_Button
            popular_search {
              data {
                attributes {
                  Module_Name
                  ModuleType {
                    ... on GLSTRAPI_ComponentModContentBlock {
                      id
                      Title
                      Content
                    }
                  }
                }
              }
            }
            Add_Modules {
              ... on GLSTRAPI_ComponentModHeaderBanner {
                id
                __typename
                Header_Title
                Content
                CTA_2_URL
                CTA_2_Label
                CTA_1_URL
                CTA_1_Label
                Banner_Image {
                  data {
                    attributes {
                      alternativeText
                      url
                    }
                  }
                }
                Show_Banner_Google_Review
              }
               __typename
              ... on GLSTRAPI_ComponentModBlogCategoriesList {
              id
              categories {
              data {
                attributes {
                  category_name
                }
              }
              } 
              }
              ... on GLSTRAPI_ComponentComFeaturedNewProjectsModuleQatar {
                id
                __typename
                New_Project_Title
                New_Project_CTA_Label
                New_Project_CTA_Link
                Show_New_Projects_Qatar
              }
              ... on GLSTRAPI_ComponentComGraphsBlock {
                id
                __typename
                Title
                Content
                Add_Tabs {
                  CTA_1_Label
                  CTA_1_URL
                  Tab_Content
                  Tab_Name
                  Tab_Title
                  id
                  Graph_Image {
                    Embed_Code
                    id
                    Title
                  }
                }
              }
              ... on GLSTRAPI_ComponentModVideo {
                id
                __typename
                Background_Image {
                  data {
                    id
                    attributes {
                      alternativeText
                      url
                    }
                  }
                }
                Video_Label
                Embed_Video_URL
                Upload_Video {
                  data {
                    attributes {
                      alternativeText
                      url
                    }
                  }
                }
              }
              ... on GLSTRAPI_ComponentModBottomBlock {
                __typename
                id
                Bottom_Title: Title
                CTA_1_URL
                CTA_1_Label
                Bottom_Content: Content
                Module_Class
              }
              ... on GLSTRAPI_ComponentModShowProperty {
                __typename
                id
                Show_Property
                Title
                Stb_List {
                  Stb_List_Item
                  Stb_List_Title
                  id
                }
              }
              ... on GLSTRAPI_ComponentModIntroCopyBlock {
                __typename
                id
                Intro_Copy_Title
                Intro_Copy_Content
              }
              ... on GLSTRAPI_ComponentModShowInsights {
                __typename
                id
                Show_Insights
                Filter_by_Tag
              }
              ... on GLSTRAPI_ComponentModShowGoogleReviews {
                __typename
                id
                Show_Google_Review
              }
            }
          }
        }
      }
    }
}`


export default HomeTemplate